import { cloneElement } from "react";

const icons = {
  carry: (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5793_1230)">
        <rect
          x="26.1553"
          y="80.4082"
          width="18.9771"
          height="39.8519"
          rx="6"
          transform="rotate(45 26.1553 80.4082)"
          fill="#FF2C6B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.5975 9.07758C81.4701 6.62881 84.2133 4.99462 87.2582 4.51383L112.609 0.511029C116.633 -0.124328 120.107 3.34944 119.472 7.37338L115.469 32.7245C114.988 35.7694 113.354 38.5126 110.905 40.3852L57.6901 81.079L71.563 94.952C73.9062 97.2951 73.9062 101.094 71.563 103.437L69.3132 105.687C66.9701 108.03 63.1711 108.03 60.828 105.687L14.296 59.1551C11.9529 56.8119 11.9529 53.0129 14.296 50.6698L16.5458 48.42C18.889 46.0769 22.6879 46.0769 25.0311 48.42L38.9037 62.2926L79.5975 9.07758ZM48.3203 71.7092L48.274 71.6629C46.4443 69.8083 46.452 66.8217 48.297 64.9767L92.5792 20.6946C94.432 18.8418 97.4359 18.8418 99.2886 20.6946C101.141 22.5473 101.141 25.5512 99.2886 27.404L55.0065 71.6861C53.1614 73.5312 50.1748 73.5388 48.3203 71.7092Z"
          fill="#FF2C6B"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_1230">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  midlaner: (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5793_1231)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.547 30.407L119.509 7.6106C120.22 3.51986 116.661 -0.0391055 112.571 0.671843L89.7742 4.63375C85.7829 5.3274 84.2303 10.2309 87.0948 13.0954L93.5219 19.5226L87.8509 25.1935C66.2645 7.06075 34.4894 7.29328 13.1643 25.8911C12.3633 26.5897 11.1513 26.5734 10.3997 25.8219C9.37204 24.7942 7.70583 24.7942 6.67814 25.8219L2.17497 30.325C1.0034 31.4966 1.0034 33.3961 2.17497 34.5677L9.22877 41.6215L18.0244 95.0201L2.67508 110.369C0.722452 112.322 0.722447 115.488 2.67507 117.44C4.6277 119.393 7.79352 119.393 9.74614 117.44L25.0483 102.138L78.5598 110.952L85.6136 118.006C86.7851 119.178 88.6846 119.178 89.8562 118.006L94.3594 113.503C95.3871 112.475 95.3871 110.809 94.3594 109.782C93.6078 109.03 93.5915 107.818 94.2901 107.017C112.908 85.6684 113.121 53.8466 94.9277 32.259L100.593 26.5936L107.086 33.0864C109.95 35.9509 114.854 34.3982 115.547 30.407ZM77.1891 35.8554C61.4736 23.465 39.0325 23.7425 23.6182 36.6878L31.0766 81.9679L77.1891 35.8554ZM38.1005 89.0861L84.2679 42.9187C96.7171 58.6377 96.4589 81.1247 83.4935 96.563L38.1005 89.0861Z"
          fill="#41A4FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_1231">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  offlaner: (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5793_1232)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60 120C80 120 120 93.1371 120 60V9.98923C120 4.46639 115.537 1.56659e-05 110.014 1.29897e-05L60 0L9.9883 1.39457e-05C4.46545 1.69348e-05 6.71417e-06 4.46927 1.35719e-05 9.99212L4.8399e-05 60C4.8399e-05 93.1371 40 120 60 120ZM62.847 19H76.5781C78.9066 19 80.3474 21.5371 79.1546 23.5368L66.6649 44.4754C65.8697 45.8086 66.8303 47.5 68.3825 47.5H79.0209C80.7282 47.5 81.6505 49.5014 80.5414 50.7993L40.0501 98.1827C39.3231 99.0335 37.9608 98.2301 38.3534 97.1822L51.6484 61.7018C52.1383 60.3944 51.1717 59 49.7755 59H40.0029C38.4683 59 37.5054 57.3429 38.2654 56.0096L57.6344 22.0288C58.7018 20.1562 60.6916 19 62.847 19Z"
          fill="#B061FE"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_1232">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  softSupport: (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5793_1228)">
        <rect y="71.9968" width="20" height="42" rx="6" fill="#FFA902" />
        <path
          d="M25 103.876V81.4968C25 78.1831 27.6863 75.4968 31 75.4968H31.33C37.6851 75.4968 43.9488 77.0111 49.6021 79.9141L50.8979 80.5795C56.5512 83.4826 62.8149 84.9968 69.17 84.9968H79C82.3137 84.9968 85 87.6831 85 90.9968C85 94.3105 82.3137 96.9968 79 96.9968H53.5C51.8431 96.9968 50.5 98.34 50.5 99.9968C50.5 101.654 51.8431 102.997 53.5 102.997H80.3712C85.9652 102.997 90.5 98.462 90.5 92.868C90.5 91.7292 91.1679 90.6963 92.2064 90.229L99.3707 87.005C106.07 83.9904 113.935 85.432 119.13 90.6265C120.546 92.0428 120.167 94.429 118.382 95.3371L79.8725 114.924C69.7688 120.063 57.9592 120.673 47.3793 116.604L28.8461 109.476C26.529 108.585 25 106.359 25 103.876Z"
          fill="#FFA902"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90 41.9969C90 58.0132 77.0163 70.9969 61 70.9969C44.9837 70.9969 32 58.0132 32 41.9969C32 32.9012 36.1874 24.7836 42.7396 19.4666C43.3113 19.0027 44.1719 19.3355 44.3878 20.0394C44.9869 21.9933 46.2029 24.8153 48.2401 27.1779C48.6293 27.6292 49.3242 27.5919 49.6332 27.0822C51.9824 23.2061 54.0306 14.084 47.5638 1.46651C47.1699 0.697916 47.8336 -0.196609 48.6647 0.0382845C59.7765 3.17876 78.4208 13.7311 79.4602 35.7422C79.4904 36.3811 80.0871 36.8406 80.6756 36.5901C82.1833 35.9484 84.4614 34.6002 86.7326 32.2369C87.255 31.6933 88.1818 31.8393 88.4273 32.5522C89.4466 35.5128 90 38.6902 90 41.9969ZM72.0037 49.7617C72.0037 56.519 66.636 61.997 60.0147 61.997C53.3933 61.997 48.0257 56.519 48.0257 49.7617C47.7392 43.6216 49.7045 34.7992 61.4366 30.7941C62.33 30.4891 63.0876 31.5571 62.6374 32.3869C60.9431 35.5098 59.4927 40.0585 61.3651 43.7477C61.6101 44.2304 62.2333 44.3688 62.667 44.0451C63.5241 43.4055 64.3657 42.4365 64.8741 40.1254C65.0086 39.514 65.6341 39.1286 66.1862 39.4237C68.6067 40.7175 72.0037 43.9453 72.0037 49.7617Z"
          fill="#FFA902"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_1228">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  hardSupport: (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5793_1229)">
        <rect y="71.9968" width="20" height="42" rx="6" fill="#54FFE0" />
        <path
          d="M25 103.876V81.4968C25 78.1831 27.6863 75.4968 31 75.4968H31.33C37.6851 75.4968 43.9488 77.0111 49.6021 79.9141L50.8979 80.5795C56.5512 83.4826 62.8149 84.9968 69.17 84.9968H79C82.3137 84.9968 85 87.6831 85 90.9968C85 94.3105 82.3137 96.9968 79 96.9968H53.5C51.8431 96.9968 50.5 98.34 50.5 99.9968C50.5 101.654 51.8431 102.997 53.5 102.997H80.3712C85.9652 102.997 90.5 98.462 90.5 92.868C90.5 91.7292 91.1679 90.6963 92.2064 90.229L99.3707 87.005C106.07 83.9904 113.935 85.432 119.13 90.6265C120.546 92.0428 120.167 94.429 118.382 95.3371L79.8725 114.924C69.7688 120.063 57.9592 120.673 47.3793 116.604L28.8461 109.476C26.529 108.585 25 106.359 25 103.876Z"
          fill="#54FFE0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.625 19.625C42.625 18.1753 41.4497 17 40 17C38.5503 17 37.375 18.1753 37.375 19.625V20.3409C37.375 28.644 30.644 35.375 22.3409 35.375H21.625C20.1753 35.375 19 36.5503 19 38C19 39.4497 20.1753 40.625 21.625 40.625H22.3409C30.644 40.625 37.375 47.356 37.375 55.6591V56.375C37.375 57.8247 38.5503 59 40 59C41.4497 59 42.625 57.8247 42.625 56.375V55.6591C42.625 47.356 49.356 40.625 57.6591 40.625H58.375C59.8247 40.625 61 39.4497 61 38C61 36.5503 59.8247 35.375 58.375 35.375H57.6591C49.356 35.375 42.625 28.644 42.625 20.3409V19.625Z"
          fill="#54FFE0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.375 1.375C73.375 0.615609 72.7594 0 72 0C71.2406 0 70.625 0.615608 70.625 1.375V1.75C70.625 6.09924 67.0992 9.625 62.75 9.625H62.375C61.6156 9.625 61 10.2406 61 11C61 11.7594 61.6156 12.375 62.375 12.375H62.75C67.0992 12.375 70.625 15.9008 70.625 20.25V20.625C70.625 21.3844 71.2406 22 72 22C72.7594 22 73.375 21.3844 73.375 20.625V20.25C73.375 15.9008 76.9008 12.375 81.25 12.375H81.625C82.3844 12.375 83 11.7594 83 11C83 10.2406 82.3844 9.625 81.625 9.625H81.25C76.9008 9.625 73.375 6.09924 73.375 1.75V1.375Z"
          fill="#54FFE0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.125 40.125C85.125 38.9514 84.1736 38 83 38C81.8264 38 80.875 38.9514 80.875 40.125V40.7045C80.875 47.4261 75.4261 52.875 68.7045 52.875H68.125C66.9514 52.875 66 53.8264 66 55C66 56.1736 66.9514 57.125 68.125 57.125H68.7045C75.4261 57.125 80.875 62.5739 80.875 69.2955V69.875C80.875 71.0486 81.8264 72 83 72C84.1736 72 85.125 71.0486 85.125 69.875V69.2955C85.125 62.5739 90.5739 57.125 97.2955 57.125H97.875C99.0486 57.125 100 56.1736 100 55C100 53.8264 99.0486 52.875 97.875 52.875H97.2955C90.5739 52.875 85.125 47.4261 85.125 40.7045V40.125Z"
          fill="#54FFE0"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_1229">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  closeCircled: (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58C45.464 58 58 45.464 58 30ZM60 30C60 13.4315 46.5685 5.55324e-07 30 3.57746e-07C13.4315 1.60169e-07 5.55324e-07 13.4315 3.57746e-07 30C1.60169e-07 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8075 40.6066L40.6064 20.8076L39.1922 19.3934L19.3932 39.1924L20.8075 40.6066Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.6066 39.1923L20.8076 19.3933L19.3934 20.8075L39.1924 40.6065L40.6066 39.1923Z"
        fill="white"
      />
    </svg>
  ),
  anonUser: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
        stroke="white"
        strokeLinecap="round"
      />
      <circle cx="12" cy="8" r="4" stroke="white" strokeLinecap="round" />
    </svg>
  ),
  blastCoin: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.31368 4.96093L9.37593 2.69994L11.7012 7.64596L8.31368 4.96093ZM8.9211 8.91375L8.34292 7.68519L7.71363 9.02634L5.44302 9.23701L7.28066 5.32704L10.6682 8.01208L11.0008 8.72125L8.9211 8.91375ZM13.5 13.4999L11.6579 9.57908L9.57729 9.77249L10.3957 11.5141H7.60339L8.36982 9.88417L6.1001 10.0948L4.5 13.4999H13.5Z"
        fill="inherit"
      />
    </svg>
  ),
  info: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.94704 11.3035H8.10419V7.10346H6.94704V11.3035ZM6.86133 5.47489C6.86133 5.81775 7.15276 6.11775 7.52133 6.11775C7.8899 6.11775 8.1899 5.81775 8.1899 5.47489C8.1899 5.13203 7.89847 4.83203 7.5299 4.83203C7.16133 4.83203 6.86133 5.13203 6.86133 5.47489Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50415 14.1184C10.8574 14.1184 13.5757 11.4001 13.5757 8.04688C13.5757 4.69367 10.8574 1.97536 7.50415 1.97536C4.15094 1.97536 1.43263 4.69367 1.43263 8.04688C1.43263 11.4001 4.15094 14.1184 7.50415 14.1184ZM7.50415 15.5469C11.6463 15.5469 15.0042 12.189 15.0042 8.04688C15.0042 3.90474 11.6463 0.546875 7.50415 0.546875C3.36202 0.546875 0.00415039 3.90474 0.00415039 8.04688C0.00415039 12.189 3.36202 15.5469 7.50415 15.5469Z"
        fill="currentColor"
      />
    </svg>
  ),
  burgerMenu: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3.85C20 3.38056 19.6195 3 19.15 3H3.85003C3.38058 3 3.00003 3.38056 3.00003 3.85C3.00003 4.31944 3.38058 4.7 3.85003 4.7H19.15C19.6195 4.7 20 4.31944 20 3.85ZM20 11.5C20 11.0305 19.6195 10.65 19.15 10.65H3.85003C3.38058 10.65 3.00003 11.0305 3.00003 11.5C3.00003 11.9694 3.38058 12.35 3.85003 12.35H19.15C19.6195 12.35 20 11.9694 20 11.5ZM3 19.15C3 18.6806 3.38056 18.3 3.85 18.3H12.1155C12.585 18.3 12.9655 18.6806 12.9655 19.15C12.9655 19.6195 12.585 20 12.1155 20H3.85C3.38056 20 3 19.6195 3 19.15Z"
        fill="white"
      />
    </svg>
  ),
  magnifyingGlass: (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.067 6.88677C10.067 9.11441 8.26114 10.9203 6.0335 10.9203C3.80586 10.9203 2 9.11441 2 6.88677C2 4.65913 3.80586 2.85327 6.0335 2.85327C8.26114 2.85327 10.067 4.65913 10.067 6.88677ZM9.5885 11.7622C8.59149 12.4905 7.36269 12.9203 6.0335 12.9203C2.70129 12.9203 0 10.219 0 6.88677C0 3.55456 2.70129 0.853271 6.0335 0.853271C9.36571 0.853271 12.067 3.55456 12.067 6.88677C12.067 8.16774 11.6678 9.35547 10.987 10.3323L14.0944 13.4397C14.485 13.8303 14.485 14.4634 14.0944 14.854C13.7039 15.2445 13.0707 15.2445 12.6802 14.854L9.5885 11.7622Z"
        fill="inherit"
      />
    </svg>
  ),
  chevronUpDown: (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m3.90677 1.53351 1.65555 2.36506c.32476.46394-.00715 1.10142-.57347 1.10142H1.67777c-.56631 0-.898222-.63748-.57346-1.10142l1.65554-2.36506c.27867-.3981.86825-.3981 1.14692 0ZM2.75985 10.4665 1.10431 8.10142c-.324762-.46395.00715-1.10143.57346-1.10143h3.31108c.56632 0 .89823.63748.57347 1.10142L3.90678 10.4665c-.27868.3981-.86826.3981-1.14693 0Z"
      />
    </svg>
  ),
  chevronDown: (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09329 3.46647.437744 1.10142C.112983.637471.44489-.00000835 1.01121-.00000838l3.31108-1.4e-7c.56632-3e-8 .89822.63747852.57346 1.10141852L3.24021 3.46647c-.27867.39811-.86825.39811-1.14692 0Z"
      />
    </svg>
  ),
  chevronUp: (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24021.533526 4.89575 2.89859c.32476.46394-.00714 1.10142-.57346 1.10142H1.01121c-.566319 0-.898227-.63748-.573466-1.10142L2.09329.533526c.27867-.398102.86825-.398102 1.14692 0Z"
      />
    </svg>
  ),
  arrowRight: (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.955139 5.2e-7.378188.576952 5.80124 6 .378189 11.423.95514 12l6-6L.955139 5.2e-7Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  deadlockAbilityLevel: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.748322 6.25244c-.7810483.78104-.7810467 2.04738.000002 2.82842L5.73284 14.0654c.78105.781 2.04738.781 2.82843 0l4.98453-4.98454c.781-.78105.781-2.04738 0-2.82843L8.56127 1.26791c-.78105-.781044-2.04738-.781042-2.82843.00001L.748322 6.25244ZM7.96744 4.21336v3.10758h2.41706L6.5863 11.1191V8.01151H4.16929l3.79815-3.79815Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  expandRight: (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58C45.464 58 58 45.464 58 30ZM60 30C60 13.4315 46.5685 5.55324e-07 30 3.57746e-07C13.4315 1.60169e-07 5.55324e-07 13.4315 3.57746e-07 30C1.60169e-07 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.707 16.5859L29.2927 18.0001L42.5856 31.293L29.2927 44.5859L30.707 46.0002L45.4141 31.293L30.707 16.5859Z"
        fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 45.293V17.293H21V45.293H19Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 30.293L44 30.293L44 32.293L20 32.293L20 30.293Z"
        fill="currentColor"
      />
    </svg>
  ),
  expandLeft: (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 45.464 14.536 58 30 58C45.464 58 58 45.464 58 30ZM60 30C60 13.4315 46.5685 5.55324e-07 30 3.57746e-07C13.4315 1.60169e-07 5.55324e-07 13.4315 3.57746e-07 30C1.60169e-07 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.293 16.5859L30.7073 18.0001L17.4144 31.293L30.7073 44.5859L29.293 46.0002L14.5859 31.293L29.293 16.5859Z"
        fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M41 45.293V17.293H39V45.293H41Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 30.293L16 30.293L16 32.293L40 32.293L40 30.293Z"
        fill="currentColor"
      />
    </svg>
  ),
  chatVipProductIcon: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1360_94501)">
        <path
          d="M4 2C4 0.895431 4.89543 0 6 0H22C23.1046 0 24 0.895431 24 2V18C24 19.1046 23.1046 20 22 20H6C4.89543 20 4 19.1046 4 18V2Z"
          fill="currentColor"
        />
        <path
          d="M9.26484 14H14.148H18.7352C18.8865 14 19.0141 13.8873 19.0328 13.7372L19.8915 6.86761C19.927 6.58417 19.5837 6.41628 19.3817 6.61827L17.2446 8.75541C17.1155 8.8845 16.902 8.86933 16.7925 8.72328L14.24 5.32C14.12 5.16 13.88 5.16 13.76 5.32L11.2075 8.72328C11.098 8.86933 10.8845 8.8845 10.7554 8.75541L8.61827 6.61827C8.41628 6.41628 8.07302 6.58417 8.10845 6.86761L8.96715 13.7372C8.98592 13.8873 9.11354 14 9.26484 14Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1360_94501"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1360_94501" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1360_94501" result="shape" />
        </filter>
      </defs>
    </svg>
  ),
  circleArrow: (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
      <path
        fill="#FFFE3E"
        fillRule="evenodd"
        d="M15.9667 8c0-4.12373-3.343-7.466667-7.4667-7.466667C4.37627.533333 1.03333 3.87627 1.03333 8c0 4.1237 3.34294 7.4667 7.46667 7.4667 4.1237 0 7.4667-3.343 7.4667-7.4667ZM16.5 8C16.5 3.58172 12.9183 1.5e-7 8.5 1e-7 4.08172 4e-8 .5 3.58172.5 8c0 4.4183 3.58172 8 8 8 4.4183 0 8-3.5817 8-8Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFE3E"
        fillRule="evenodd"
        d="m8.84477 4.42297.37712.37713-3.54477 3.54477 3.54477 3.54473-.37712.3772-3.9219-3.92193 3.9219-3.9219Z"
        clipRule="evenodd"
      />
      <path fill="#FFFE3E" fillRule="evenodd" d="m11.7 8.07813-6.4-.00001v.53334h6.4v-.53333Z" clipRule="evenodd" />
    </svg>
  ),
  locationPin: (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
      <path
        d="M4.8 6.20728C5.13 6.20728 5.4125 6.08978 5.6475 5.85478C5.8825 5.61978 6 5.33728 6 5.00728C6 4.67728 5.8825 4.39478 5.6475 4.15978C5.4125 3.92478 5.13 3.80728 4.8 3.80728C4.47 3.80728 4.1875 3.92478 3.9525 4.15978C3.7175 4.39478 3.6 4.67728 3.6 5.00728C3.6 5.33728 3.7175 5.61978 3.9525 5.85478C4.1875 6.08978 4.47 6.20728 4.8 6.20728ZM4.8 10.6173C6.02 9.49728 6.925 8.47978 7.515 7.56477C8.105 6.64978 8.4 5.83728 8.4 5.12728C8.4 4.03728 8.0525 3.14478 7.3575 2.44978C6.6625 1.75478 5.81 1.40728 4.8 1.40728C3.79 1.40728 2.9375 1.75478 2.2425 2.44978C1.5475 3.14478 1.2 4.03728 1.2 5.12728C1.2 5.83728 1.495 6.64978 2.085 7.56477C2.675 8.47978 3.58 9.49728 4.8 10.6173ZM4.8 12.2073C3.19 10.8373 1.9875 9.56478 1.1925 8.38978C0.3975 7.21478 0 6.12727 0 5.12728C0 3.62728 0.4825 2.43228 1.4475 1.54228C2.4125 0.652275 3.53 0.207275 4.8 0.207275C6.07 0.207275 7.1875 0.652275 8.1525 1.54228C9.1175 2.43228 9.6 3.62728 9.6 5.12728C9.6 6.12727 9.2025 7.21478 8.4075 8.38978C7.6125 9.56478 6.41 10.8373 4.8 12.2073Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export type IconName = keyof typeof icons;

interface IconProps {
  icon: IconName;
  className?: string;
  onClick?: () => void;
}

function Icon({ icon, className, onClick }: IconProps) {
  const el = icons[icon] as JSX.Element;
  // eslint-disable-next-line
  const propClassName: string | undefined = el.props.className;
  const classNames = [propClassName, className].filter((c) => c);

  return cloneElement(el, {
    className: `${classNames.join(" ")}`,
    onClick,
  });
}

export { Icon };
